export enum FieldType {
    TEXT = 'text',
    INFO_TEXT = 'infoText',
    HEADLINE = 'headline',
    TOGGLE = 'toggle',
    SELECT = 'select',
    LINK = 'link',
    MEDIA = 'media',
    COLLECTION = 'collection',
}

export enum SelectControlType {
    DROPDOWN = 'dropdown',
    RADIO = 'radio',
}

export type FieldDefinition =
    | {
          type: FieldType.TEXT
          label: string
          valueName: string
          multiLine?: boolean
          lines?: number
      }
    | {
          type: FieldType.INFO_TEXT
          label: string
      }
    | {
          type: FieldType.LINK
          label: string
          valueName: string
          disableInternal?: boolean
          disableExternal?: boolean
          disableFile?: boolean
      }
    | {
          type: FieldType.MEDIA
          label: string
          valueName: string
      }
    | {
          type: FieldType.HEADLINE
          text: string
      }
    | {
          type: FieldType.TOGGLE
          label: string
          valueName: string
      }
    | {
          type: FieldType.SELECT
          label: string
          valueName: string
          control: SelectControlType
          options: { value: string; label: string }[]
      }
    | {
          type: FieldType.COLLECTION
          title: string
          itemTitle: string
          valueName: string
          min?: number
          max?: number
          fields: FieldDefinition[]
      }

export type ElementDefinition = {
    name: string
    identifier: string
    initialData: any
    fields: FieldDefinition[]
}

export const elementDefinitions: ElementDefinition[] = [
    {
        name: 'Headline',
        identifier: 'headline',
        initialData: {
            headline: 'Headline',
            subline: '',
            type: 'h2',
            style: 'h2',
            underline: 'no-underline',
            bottomMargin: 'default-margin',
            topMargin: 'default-margin',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Headline',
                valueName: 'headline',
                multiLine: true,
                lines: 1,
            },
            {
                type: FieldType.SELECT,
                label: 'Type',
                valueName: 'type',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'h1', label: 'H1' },
                    { value: 'h2', label: 'H2' },
                    { value: 'h3', label: 'H3' },
                    { value: 'h4', label: 'H4' },
                    { value: 'h5', label: 'H5' },
                    { value: 'h6', label: 'H6' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Style',
                valueName: 'style',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'h1', label: 'H1' },
                    { value: 'h2', label: 'H2' },
                    { value: 'h3', label: 'H3' },
                    { value: 'h4', label: 'H4' },
                    { value: 'h5', label: 'H5' },
                    { value: 'h6', label: 'H6' },
                ],
            },
            {
                type: FieldType.TEXT,
                label: 'Subline',
                valueName: 'subline',
            },
            {
                type: FieldType.SELECT,
                label: 'Underline',
                valueName: 'underline',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'no-underline', label: 'None' },
                    { value: 'underline', label: 'Underline' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Bottom Margin',
                valueName: 'bottomMargin',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'default-margin', label: 'Default' },
                    { value: 'no-margin', label: 'No Margin' },
                    { value: 'half-margin', label: 'Half Margin' },
                    { value: 'full-margin', label: 'Full Margin' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Top Margin',
                valueName: 'topMargin',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'default-margin', label: 'Default' },
                    { value: 'no-margin', label: 'No Margin' },
                    { value: 'half-margin', label: 'Half Margin' },
                    { value: 'full-margin', label: 'Full Margin' },
                ],
            },
        ],
    },
    {
        name: 'Headline with BG',
        identifier: 'headlineBackground',
        initialData: { headline: 'Headline', backgroundImage: { __qbd_fileId: '', __qbd_altText: '' } },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Headline',
                valueName: 'headline',
            },
            {
                type: FieldType.MEDIA,
                label: 'Background Image',
                valueName: 'backgroundImage',
            },
        ],
    },
    {
        name: 'Two column text',
        identifier: 'twoColumnText',
        initialData: {
            text: 'And here is the textAnd here',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Body',
                valueName: 'text',
                multiLine: true,
                lines: 5,
            },
        ],
    },
    {
        name: 'Header',
        identifier: 'header',
        initialData: {
            backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
        },
        fields: [
            {
                type: FieldType.MEDIA,
                label: 'Background Image',
                valueName: 'backgroundImage',
            },
        ],
    },
    {
        name: 'Stage',
        identifier: 'stage',
        initialData: {
            title: 'Headline',
            variant: 'white',
            backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
            header: 'false',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Title',
                valueName: 'title',
            },
            {
                type: FieldType.SELECT,
                label: 'Variant',
                valueName: 'variant',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'white', label: 'White' },
                    { value: 'black', label: 'Black' },
                    { value: 'blackBG', label: 'Black background' },
                    { value: 'whiteBG', label: 'White background' },
                ],
            },
            {
                type: FieldType.MEDIA,
                label: 'Background Image',
                valueName: 'backgroundImage',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Includes header',
                valueName: 'header',
            },
        ],
    },
    {
        name: 'Stage Carousel',
        identifier: 'stageCarousel',
        initialData: {
            items: [
                {
                    title: 'Title',
                    variant: 'whiteBG',
                    backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
                    markdownText: '',
                    boxButtonLink: '',
                    boxButtonText: '',
                    box: 'false',
                },
            ],
            autoplay: 'false',
            header: 'true',
            firstItemHeadlineSEO: 'true',
        },
        fields: [
            {
                type: FieldType.TOGGLE,
                label: 'Includes header',
                valueName: 'header',
            },
            {
                type: FieldType.TOGGLE,
                label: 'First item is rendered as <h1> for SEO purposes, no change in visuals',
                valueName: 'firstItemHeadlineSEO',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Autoplay',
                valueName: 'autoplay',
            },
            {
                type: FieldType.COLLECTION,
                title: 'Stage Carousel',
                itemTitle: 'Item ',
                valueName: 'items',
                fields: [
                    {
                        type: FieldType.TEXT,
                        label: 'Title',
                        valueName: 'title',
                    },
                    {
                        type: FieldType.TOGGLE,
                        label: 'Is a box',
                        valueName: 'box',
                    },
                    {
                        type: FieldType.SELECT,
                        label: 'Variant if not a box',
                        valueName: 'variant',
                        control: SelectControlType.DROPDOWN,
                        options: [
                            {
                                value: 'white',
                                label: 'White',
                            },
                            {
                                value: 'black',
                                label: 'Black',
                            },
                            {
                                value: 'blackBG',
                                label: 'Black background',
                            },
                            {
                                value: 'whiteBG',
                                label: 'White background',
                            },
                        ],
                    },
                    {
                        type: FieldType.MEDIA,
                        label: 'Background Image',
                        valueName: 'backgroundImage',
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Markdown Text',
                        valueName: 'markdownText',
                        multiLine: true,
                    },
                    {
                        type: FieldType.LINK,
                        label: 'Box Button Link',
                        valueName: 'boxButtonLink',
                        disableFile: true,
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Box Button Text',
                        valueName: 'boxButtonText',
                    },
                ],
            },
        ],
    },
    {
        name: 'Button',
        identifier: 'button',
        initialData: {
            label: 'Button',
            link: { url: '' },
            justify: 'flex-end',
            subtext: '',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Label',
                valueName: 'label',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
            {
                type: FieldType.SELECT,
                label: 'Justify (Horizontal alignment)',
                valueName: 'justify',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'flex-start', label: 'Start' },
                    { value: 'center', label: 'Center' },
                    { value: 'flex-end', label: 'End' },
                ],
            },
            {
                type: FieldType.TEXT,
                label: 'Optional Subtext',
                valueName: 'subtext',
            },
        ],
    },
    {
        name: 'ButtonList',
        identifier: 'buttonList',
        initialData: {
            active: 'label0',
            items: [{ label: 'label0', link: { url: '' } }],
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Active Item Label',
                valueName: 'active',
            },
            {
                type: FieldType.COLLECTION,
                title: 'Buttons',
                itemTitle: 'Button ',
                valueName: 'items',
                fields: [
                    {
                        type: FieldType.TEXT,
                        label: 'Label',
                        valueName: 'label',
                    },
                    {
                        type: FieldType.LINK,
                        label: 'Link',
                        valueName: 'link',
                    },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Font Color',
                valueName: 'fontColor',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'white', label: 'White' },
                    { value: 'black', label: 'Black' },
                ],
            },
        ],
    },
    {
        name: 'Text',
        identifier: 'text',
        initialData: {
            content: 'Lorem ipsum sit amet et dolor.',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Content',
                valueName: 'content',
                multiLine: true,
                lines: 8,
            },
        ],
    },
    {
        name: 'Image',
        identifier: 'image',
        initialData: {
            label: 'Image',
            media: { __qbd_fileId: '', __qbd_altText: '' },
            link: { url: '' },
        },
        fields: [
            {
                type: FieldType.MEDIA,
                label: 'Media',
                valueName: 'media',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
        ],
    },
    {
        name: 'Quickmount',
        identifier: 'quickmount',
        initialData: {
            name: '',
            description: '',
            media: { __qbd_fileId: '', __qbd_altText: '' },
            link: { url: '' },
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Description',
                valueName: 'description',
            },
            {
                type: FieldType.MEDIA,
                label: 'Media',
                valueName: 'media',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
        ],
    },
    {
        name: 'Product Group',
        identifier: 'productGroup',
        initialData: {
            name: '',
            description: '',
            media: { __qbd_fileId: '', __qbd_altText: '' },
            link: { url: '' },
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Description',
                valueName: 'description',
            },
            {
                type: FieldType.MEDIA,
                label: 'Media',
                valueName: 'media',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
        ],
    },
    {
        name: 'Pedalsafe',
        identifier: 'pedalsafe',
        initialData: {
            name: '',
            description: '',
            media: { __qbd_fileId: '', __qbd_altText: '' },
            link: { url: '' },
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Description',
                valueName: 'description',
            },
            {
                type: FieldType.MEDIA,
                label: 'Media',
                valueName: 'media',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
        ],
    },
    {
        name: 'Highlight',
        identifier: 'highlight',
        initialData: {
            label: 'Image',
            title: '',
            description: '',
            media: { __qbd_fileId: '', __qbd_altText: '' },
            link: { url: '' },
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Title',
                valueName: 'title',
            },
            {
                type: FieldType.TEXT,
                label: 'Description',
                valueName: 'description',
            },
            {
                type: FieldType.MEDIA,
                label: 'Media',
                valueName: 'media',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
        ],
    },
    {
        name: 'Patchbay teaser',
        identifier: 'patchbay',
        initialData: {
            title: '',
            description: '',
            name: '',
            sub: '',
            rockboardId: '',
            media: { __qbd_fileId: '', __qbd_altText: '' },
            link: { url: '' },
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Title',
                valueName: 'title',
            },
            {
                type: FieldType.TEXT,
                label: 'Description',
                valueName: 'description',
            },
            {
                type: FieldType.TEXT,
                label: 'Real name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Short description',
                valueName: 'sub',
            },
            {
                type: FieldType.TEXT,
                label: 'Rockboard ID',
                valueName: 'rockboardId',
            },
            {
                type: FieldType.MEDIA,
                label: 'Media',
                valueName: 'media',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
        ],
    },
    {
        name: 'Related Product',
        identifier: 'relatedProduct',
        initialData: {
            title: '',
            media: { __qbd_fileId: '', __qbd_altText: '' },
            link: { url: '' },
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Title',
                valueName: 'title',
            },
            {
                type: FieldType.MEDIA,
                label: 'Media',
                valueName: 'media',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
        ],
    },
    {
        name: 'Dynamic Section',
        identifier: 'dynamicSection',
        initialData: {
            regions: [[]],
            paddingBefore: 'true',
            paddingAfter: 'true',
            bright: 'false',
            itemsPerRowLarge: '3',
            itemsPerRowMedium: '2',
            itemsPerRowSmall: '1',
        },
        fields: [
            {
                type: FieldType.SELECT,
                label: 'Columns L',
                valueName: 'itemsPerRowLarge',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Columns M',
                valueName: 'itemsPerRowMedium',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Columns S',
                valueName: 'itemsPerRowSmall',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                ],
            },
            {
                type: FieldType.TOGGLE,
                label: 'Bright text',
                valueName: 'bright',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding before',
                valueName: 'paddingBefore',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding after',
                valueName: 'paddingAfter',
            },
        ],
    },
    {
        name: 'Section (1 Column)',
        identifier: 'sectionOne',
        initialData: {
            type: 'main_content',
            backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
            regions: [[]],
            paddingBefore: 'true',
            paddingAfter: 'true',
            bright: 'false',
            dialog: 'false',
        },
        fields: [
            {
                type: FieldType.SELECT,
                label: 'Region Type',
                valueName: 'type',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'header', label: 'Header' },
                    { value: 'stage', label: 'Stage' },
                    { value: 'main_content', label: 'Content area' },
                    { value: 'subcontent', label: 'Subcontent' },
                    { value: 'footer', label: 'Footer' },
                ],
            },
            {
                type: FieldType.MEDIA,
                label: 'Background Image',
                valueName: 'backgroundImage',
            },

            {
                type: FieldType.TOGGLE,
                label: 'Bright text',
                valueName: 'bright',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding before',
                valueName: 'paddingBefore',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding after',
                valueName: 'paddingAfter',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Inside a dialog',
                valueName: 'dialog',
            },
        ],
    },
    {
        name: 'Section (2 Columns)',
        identifier: 'sectionTwo',
        initialData: {
            type: 'main_content',
            backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
            regions: [[], []],
            paddingBefore: 'true',
            paddingAfter: 'true',
            bright: 'false',
            Col1Xs: '12',
            Col2Xs: '12',
            Col1Md: '6',
            Col2Md: '6',
            Col1Xl: '6',
            Col2Xl: '6',
            reverseOnMobile: 'false',
            dialog: 'false',
        },
        fields: [
            {
                type: FieldType.SELECT,
                label: 'Region Type',
                valueName: 'type',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'header', label: 'Header' },
                    { value: 'stage', label: 'Stage' },
                    { value: 'main_content', label: 'Content area' },
                    { value: 'subcontent', label: 'Subcontent' },
                    { value: 'footer', label: 'Footer' },
                ],
            },
            {
                type: FieldType.MEDIA,
                label: 'Background Image',
                valueName: 'backgroundImage',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Reverse on Mobile',
                valueName: 'reverseOnMobile',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Bright text',
                valueName: 'bright',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding before',
                valueName: 'paddingBefore',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding after',
                valueName: 'paddingAfter',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Inside a dialog',
                valueName: 'dialog',
            },
            {
                type: FieldType.SELECT,
                label: 'Column 1 XS',
                valueName: 'Col1Xs',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 2 XS',
                valueName: 'Col2Xs',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 1 Medium',
                valueName: 'Col1Md',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 2 Medium',
                valueName: 'Col2Md',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 1 XL',
                valueName: 'Col1Xl',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 2 XL',
                valueName: 'Col2Xl',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
        ],
    },
    {
        name: 'Section (3 Columns)',
        identifier: 'sectionThree',
        initialData: {
            type: 'main_content',
            regions: [[], [], []],
            backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
            bright: 'false',
            paddingBefore: 'true',
            paddingAfter: 'true',
            Col1Xs: '12',
            Col2Xs: '12',
            Col3Xs: '12',
            Col1Md: '4',
            Col2Md: '4',
            Col3Md: '4',
            Col1Xl: '4',
            Col2Xl: '4',
            Col3Xl: '4',
            reverseOnMobile: 'false',
            dialog: 'false',
        },
        fields: [
            {
                type: FieldType.SELECT,
                label: 'Region Type',
                valueName: 'type',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'header', label: 'Header' },
                    { value: 'stage', label: 'Stage' },
                    { value: 'main_content', label: 'Content area' },
                    { value: 'subcontent', label: 'Subcontent' },
                    { value: 'footer', label: 'Footer' },
                ],
            },
            {
                type: FieldType.MEDIA,
                label: 'Background Image',
                valueName: 'backgroundImage',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Reverse on Mobile',
                valueName: 'reverseOnMobile',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Bright text',
                valueName: 'bright',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding before',
                valueName: 'paddingBefore',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding after',
                valueName: 'paddingAfter',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Inside a dialog',
                valueName: 'dialog',
            },
            {
                type: FieldType.SELECT,
                label: 'Column 1 XS',
                valueName: 'Col1Xs',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 2 XS',
                valueName: 'Col2Xs',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 3 XS',
                valueName: 'Col3Xs',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 1 Medium',
                valueName: 'Col1Md',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 2 Medium',
                valueName: 'Col2Md',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 3 Medium',
                valueName: 'Col3Md',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 1 XL',
                valueName: 'Col1Xl',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 2 XL',
                valueName: 'Col2Xl',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 3 XL',
                valueName: 'Col3Xl',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
        ],
    },
    {
        name: 'Section (4 Columns)',
        identifier: 'sectionFour',
        initialData: {
            type: 'main_content',
            regions: [[], [], [], []],
            backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
            bright: 'false',
            paddingBefore: 'true',
            paddingAfter: 'true',
            Col1Xs: '6',
            Col2Xs: '6',
            Col3Xs: '6',
            Col4Xs: '6',
            Col1Md: '3',
            Col2Md: '3',
            Col3Md: '3',
            Col4Md: '3',
            Col1Xl: '3',
            Col2Xl: '3',
            Col3Xl: '3',
            Col4Xl: '3',
            reverseOnMobile: 'false',
            dialog: 'false',
        },
        fields: [
            {
                type: FieldType.SELECT,
                label: 'Region Type',
                valueName: 'type',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'header', label: 'Header' },
                    { value: 'stage', label: 'Stage' },
                    { value: 'main_content', label: 'Content area' },
                    { value: 'subcontent', label: 'Subcontent' },
                    { value: 'footer', label: 'Footer' },
                ],
            },
            {
                type: FieldType.TOGGLE,
                label: 'Bright text',
                valueName: 'bright',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Reverse on Mobile',
                valueName: 'reverseOnMobile',
            },
            {
                type: FieldType.MEDIA,
                label: 'Background Image',
                valueName: 'backgroundImage',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding before',
                valueName: 'paddingBefore',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Padding after',
                valueName: 'paddingAfter',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Inside a dialog',
                valueName: 'dialog',
            },
            {
                type: FieldType.SELECT,
                label: 'Column 1 XS',
                valueName: 'Col1Xs',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 2 XS',
                valueName: 'Col2Xs',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 3 XS',
                valueName: 'Col3Xs',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 4 XS',
                valueName: 'Col4Xs',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 1 Medium',
                valueName: 'Col1Md',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 2 Medium',
                valueName: 'Col2Md',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 3 Medium',
                valueName: 'Col3Md',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 4 Medium',
                valueName: 'Col4Md',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 1 XL',
                valueName: 'Col1Xl',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 2 XL',
                valueName: 'Col2Xl',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 3 XL',
                valueName: 'Col3Xl',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Column 4 XL',
                valueName: 'Col4Xl',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
            },
        ],
    },
    {
        name: 'Section (5 Columns)',
        identifier: 'sectionFive',
        initialData: {
            type: 'main_content',
            regions: [[], [], [], [], []],
            reverseOnMobile: 'false',
        },
        fields: [
            {
                type: FieldType.SELECT,
                label: 'Region Type',
                valueName: 'type',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'header', label: 'Header' },
                    { value: 'stage', label: 'Stage' },
                    { value: 'main_content', label: 'Content area' },
                    { value: 'subcontent', label: 'Subcontent' },
                    { value: 'footer', label: 'Footer' },
                ],
            },
            {
                type: FieldType.TOGGLE,
                label: 'Reverse on Mobile',
                valueName: 'reverseOnMobile',
            },
        ],
    },
    {
        name: 'Demo element',
        identifier: 'demoElement',
        initialData: {
            title: 'This is the secondary headline',
            toggle: false,
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Title',
                valueName: 'title',
            },
            {
                type: FieldType.INFO_TEXT,
                label: 'Dialogs can have helpful hints or texts like this one in between controls.',
            },
            {
                type: FieldType.HEADLINE,
                text: 'Demo controls:',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Toggle on/off',
                valueName: 'toggle',
            },
            {
                type: FieldType.SELECT,
                label: 'Headline size',
                valueName: 'size',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'h1', label: 'H1' },
                    { value: 'h2', label: 'H2' },
                    { value: 'h3', label: 'H3' },
                    { value: 'h4', label: 'H4' },
                    { value: 'h5', label: 'H5' },
                    { value: 'h6', label: 'H6' },
                ],
            },
        ],
    },
    {
        name: 'Related Products Teaser',
        identifier: 'relatedProductsTeaser',
        initialData: {
            headline: 'Title of the Teaser',
            backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
            products: [
                {
                    name: 'Product 1',
                    productImage: { __qbd_fileId: '', __qbd_altText: '' },
                    link: 'Click here 1 ',
                },
                {
                    name: 'Product 2',
                    productImage: { __qbd_fileId: '', __qbd_altText: '' },
                    link: 'Click here 2',
                },
            ],
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Headline',
                valueName: 'headline',
            },
            {
                type: FieldType.MEDIA,
                label: 'Background Image',
                valueName: 'backgroundImage',
            },
            {
                type: FieldType.COLLECTION,
                title: 'Products',
                itemTitle: 'Related Product ',
                valueName: 'products',
                fields: [
                    {
                        type: FieldType.TEXT,
                        label: 'Name',
                        valueName: 'name',
                    },
                    {
                        type: FieldType.MEDIA,
                        label: 'Image',
                        valueName: 'productImage',
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Link',
                        valueName: 'link',
                    },
                ],
            },
        ],
    },
    {
        name: 'Sidebar Text',
        identifier: 'sidebarText',
        initialData: {
            items: [
                {
                    title: 'Title',
                    description: 'Description',
                    button: 'Button',
                    link: { url: '' },
                },
            ],
        },
        fields: [
            {
                type: FieldType.COLLECTION,
                title: 'Sidebar items',
                itemTitle: 'Item ',
                valueName: 'items',
                fields: [
                    {
                        type: FieldType.TEXT,
                        label: 'Title',
                        valueName: 'title',
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Description',
                        valueName: 'description',
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Optional Button Label',
                        valueName: 'button',
                    },
                    {
                        type: FieldType.LINK,
                        label: 'Optional Link',
                        valueName: 'link',
                    },
                ],
            },
        ],
    },
    {
        name: 'Download area',
        identifier: 'downloadArea',
        initialData: {
            headline: 'Downloads',
            description: '',
            downloads: [
                {
                    title: 'Download 1',
                    file: { __qbd_fileId: '' },
                },
            ],
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Headline',
                valueName: 'headline',
            },
            {
                type: FieldType.TEXT,
                label: 'Description (optional)',
                valueName: 'description',
            },
            {
                type: FieldType.COLLECTION,
                title: 'Downloads',
                itemTitle: 'Download',
                valueName: 'downloads',
                fields: [
                    {
                        type: FieldType.TEXT,
                        label: 'Button title',
                        valueName: 'title',
                    },
                    {
                        type: FieldType.MEDIA,
                        label: 'File',
                        valueName: 'file',
                    },
                ],
            },
        ],
    },
    {
        name: 'Video Area',
        identifier: 'videoArea',
        initialData: {
            title: 'Videos',
            description: '',
            videos: [
                {
                    title: 'Video 1',
                    youtubeId: '',
                },
            ],
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Title',
                valueName: 'title',
            },
            {
                type: FieldType.TEXT,
                label: 'Description',
                valueName: 'description',
            },
            {
                type: FieldType.COLLECTION,
                title: 'Videos',
                itemTitle: 'Video',
                valueName: 'videos',
                fields: [
                    {
                        type: FieldType.TEXT,
                        label: 'Title',
                        valueName: 'title',
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Youtube ID',
                        valueName: 'youtubeId',
                    },
                ],
            },
        ],
    },
    {
        name: 'Single Video',
        identifier: 'singleVideo',
        initialData: {
            youtubeId: '',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Youtube ID',
                valueName: 'youtubeId',
            },
        ],
    },
    {
        name: 'Pedalboard',
        identifier: 'pedalboard',
        initialData: {
            name: 'Pedalname',
            description: 'This is a very pink pedal',
            rockboardID: '',
            series: '2',
            media: { __qbd_fileId: '', __qbd_altText: '' },
            link: { url: '' },
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Description',
                valueName: 'description',
                multiLine: true,
                lines: 1,
            },
            {
                type: FieldType.TEXT,
                label: 'Rockboard ID',
                valueName: 'rockboardID',
            },
            {
                type: FieldType.SELECT,
                label: 'Series',
                valueName: 'series',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '2', label: 'Duo' },
                    { value: '3', label: 'Tres' },
                    { value: '4', label: 'Quad' },
                    { value: '5', label: 'Cinque' },
                ],
            },
            {
                type: FieldType.MEDIA,
                label: 'Image',
                valueName: 'media',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
        ],
    },
    {
        name: 'Homepage teaser',
        identifier: 'homepageTeaser',
        initialData: {
            backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
            headline: 'Headline',
            text: 'Lorem ipsum',
            link: { url: '' },
            topMargin: 'false',
        },
        fields: [
            {
                type: FieldType.MEDIA,
                label: 'Background',
                valueName: 'backgroundImage',
            },
            {
                type: FieldType.TEXT,
                label: 'Headline',
                valueName: 'headline',
            },
            {
                type: FieldType.TEXT,
                label: 'Text',
                valueName: 'text',
                multiLine: true,
                lines: 1,
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
            {
                type: FieldType.TOGGLE,
                label: 'Top margin',
                valueName: 'topMargin',
            },
        ],
    },
    {
        name: 'Product Tile',
        identifier: 'productTile',
        initialData: {
            productImage: { __qbd_fileId: '', __qbd_altText: '' },
            headline: 'Headline',
            link: { url: '' },
            description: '',
        },
        fields: [
            {
                type: FieldType.MEDIA,
                label: 'Product',
                valueName: 'productImage',
            },
            {
                type: FieldType.TEXT,
                label: 'Headline',
                valueName: 'headline',
            },
            {
                type: FieldType.TEXT,
                label: 'Description',
                valueName: 'description',
            },
            {
                type: FieldType.LINK,
                label: 'Link',
                valueName: 'link',
            },
        ],
    },
    {
        name: 'Single item details',
        identifier: 'singleDetails',
        initialData: {
            name: 'Product name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            productID: '1234',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Product name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Rockboard Product ID',
                valueName: 'productID',
            },
        ],
    },
    {
        name: 'Multiple item details',
        identifier: 'multipleDetails',
        initialData: {
            name: 'Product name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            chooseVariantText: 'Choose Variant',
            collectionID: '1234',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Product name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Item picker label',
                valueName: 'chooseVariantText',
            },
            {
                type: FieldType.TEXT,
                label: 'Collection ID',
                valueName: 'collectionID',
            },
        ],
    },
    {
        name: 'Pedalboard details',
        identifier: 'pedalboardDetails',
        initialData: {
            name: 'Product name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            collectionID: '1234',
            teaserHeadline: 'Headline for teaser area',
            teasersBackground: { __qbd_fileId: '', __qbd_altText: '' },
            teaser1: 'Teaser 1',
            teaser2: 'Teaser 2',
            teaser3: 'Teaser 3',
            teaser4: 'Teaser 4',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Product name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Collection ID',
                valueName: 'collectionID',
            },

            {
                type: FieldType.TEXT,
                label: 'Teaser Headline',
                valueName: 'teaserHeadline',
            },
            {
                type: FieldType.MEDIA,
                label: 'Teasers background',
                valueName: 'teasersBackground',
            },
            {
                type: FieldType.TEXT,
                label: 'Teaser 1 text',
                valueName: 'teaser1',
            },
            {
                type: FieldType.TEXT,
                label: 'Teaser 2 text',
                valueName: 'teaser2',
            },
            {
                type: FieldType.TEXT,
                label: 'Teaser 3 text',
                valueName: 'teaser3',
            },
            {
                type: FieldType.TEXT,
                label: 'Teaser 4 text',
                valueName: 'teaser4',
            },
        ],
    },
    {
        name: 'Cable details',
        identifier: 'cableDetails',
        initialData: {
            name: 'Product name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            collectionID: '1234',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Product name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Collection ID',
                valueName: 'collectionID',
            },
        ],
    },
    {
        name: 'Enclosing details',
        identifier: 'enclosingDetails',
        initialData: {
            name: 'Product name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            collectionID: '1234',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Product name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Collection ID',
                valueName: 'collectionID',
            },
        ],
    },
    {
        name: 'Cable Tie details',
        identifier: 'cableTieDetails',
        initialData: {
            name: 'Product name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            collectionID: '1234',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Product name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Collection ID',
                valueName: 'collectionID',
            },
        ],
    },
    {
        name: 'Clothing details',
        identifier: 'clothingDetails',
        initialData: {
            name: 'Product name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            collectionID: '1234',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Product name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Collection ID',
                valueName: 'collectionID',
            },
        ],
    },
    {
        name: 'Mod details',
        identifier: 'modDetails',
        initialData: {
            name: 'mod name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            productID: '1234',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Mod name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Rockboard Product ID',
                valueName: 'productID',
            },
        ],
    },
    {
        name: 'QuickMount details',
        identifier: 'quickMountDetails',
        initialData: {
            name: 'name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            productID: '1234',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'QuickMount name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Rockboard Product ID',
                valueName: 'productID',
            },
        ],
    },
    {
        name: 'PedalSafe details',
        identifier: 'pedalSafeDetails',
        initialData: {
            name: 'name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            productID1: '1234',
            productID2: '6789',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'PedalSafe name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Rockboard Product ID 1',
                valueName: 'productID1',
            },
            {
                type: FieldType.TEXT,
                label: 'Rockboard Product ID 2',
                valueName: 'productID2',
            },
        ],
    },
    {
        name: 'Power supply details',
        identifier: 'powerSupplyDetails',
        initialData: {
            name: 'name',
            subline: 'Lorem ipsum sit amet et dolor',
            introText: 'Lorem ipsum sit amet et dolor',
            productID: '1234',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Power supply name',
                valueName: 'name',
            },
            {
                type: FieldType.TEXT,
                label: 'Subheadline',
                valueName: 'subline',
            },
            {
                type: FieldType.TEXT,
                label: 'Intro text',
                valueName: 'introText',
                multiLine: true,
                lines: 4,
            },
            {
                type: FieldType.TEXT,
                label: 'Rockboard Product ID',
                valueName: 'productID',
            },
        ],
    },
    {
        name: 'Testimonial carousel',
        identifier: 'testimonialCarousel',
        initialData: {
            testimonials: [
                {
                    backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
                    band: 'Band',
                    musician: 'Musician',
                    quote: 'Lorem Ipsum sit amet ',
                    boardImage: { __qbd_fileId: '', __qbd_altText: '' },
                },
            ],
        },
        fields: [
            {
                type: FieldType.COLLECTION,
                title: 'Testimonials',
                itemTitle: 'Testimonial',
                valueName: 'testimonials',
                fields: [
                    {
                        type: FieldType.MEDIA,
                        label: 'Image',
                        valueName: 'backgroundImage',
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Band',
                        valueName: 'band',
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Musician',
                        valueName: 'musician',
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Quote',
                        valueName: 'quote',
                        multiLine: true,
                        lines: 4,
                    },
                    {
                        type: FieldType.MEDIA,
                        label: 'Board image',
                        valueName: 'boardImage',
                    },
                ],
            },
        ],
    },
    {
        name: 'Artist Teaser Section',
        identifier: 'artistTeaserSection',
        initialData: {
            headline: 'Headline',
            backgroundImage: { __qbd_fileId: '' },
            text: 'Lorem ipsum',
            buttonLabel: 'Button',
            marginTop: '0',
            marginBottom: '0',
        },
        fields: [
            // Section
            {
                type: FieldType.MEDIA,
                label: 'Background Image',
                valueName: 'backgroundImage',
            },
            // Headline
            {
                type: FieldType.TEXT,
                label: 'Headline',
                valueName: 'headline',
                multiLine: true,
                lines: 1,
            },
            // Subline
            {
                type: FieldType.TEXT,
                label: 'Body',
                valueName: 'text',
                multiLine: true,
                lines: 5,
            },
            // Teaser
            {
                type: FieldType.SELECT,
                label: 'Margin Top',
                valueName: 'marginTop',
                control: SelectControlType.DROPDOWN,
                options: [
                    {
                        value: '5rem',
                        label: 'Big (5rem)',
                    },
                    {
                        value: '3.5rem',
                        label: 'Medium (3.5rem)',
                    },
                    {
                        value: '2rem',
                        label: 'Small (2rem)',
                    },
                    {
                        value: '0',
                        label: 'None',
                    },
                ],
            },
            {
                type: FieldType.SELECT,
                label: 'Margin Bottom',
                valueName: 'marginBottom',
                control: SelectControlType.DROPDOWN,
                options: [
                    {
                        value: '5rem',
                        label: 'Big (5rem)',
                    },
                    {
                        value: '3.5rem',
                        label: 'Medium (3.5rem)',
                    },
                    {
                        value: '2rem',
                        label: 'Small (2rem)',
                    },
                    {
                        value: '0',
                        label: 'None',
                    },
                ],
            },
            // Button
            {
                type: FieldType.TEXT,
                label: 'Button Label',
                valueName: 'buttonLabel',
            },
        ],
    },
    {
        name: 'Instagram Feed',
        identifier: 'instagramFeed',
        fields: [],
        initialData: {},
    },
    {
        name: 'Help Root Button',
        identifier: 'helpRootButton',
        initialData: {
            label: 'Button',
            helpPath: '',
            justify: 'center',
            subtext: '',
            backgroundImage: { __qbd_fileId: '', __qbd_altText: '' },
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Label',
                valueName: 'label',
            },
            {
                type: FieldType.TEXT,
                label: 'Link',
                valueName: 'helpPath',
            },
            {
                type: FieldType.SELECT,
                label: 'Justify (Horizontal alignment)',
                valueName: 'justify',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'flex-start', label: 'Start' },
                    { value: 'center', label: 'Center' },
                    { value: 'flex-end', label: 'End' },
                ],
            },
            {
                type: FieldType.TEXT,
                label: 'Optional Markdown Subtext',
                valueName: 'subtext',
            },
            {
                type: FieldType.MEDIA,
                label: 'Card Image',
                valueName: 'backgroundImage',
            },
        ],
    },
    {
        name: 'Help Content Button',
        identifier: 'helpContentButton',
        initialData: {
            label: 'Button',
            helpPath: '',
            justify: 'flex-end',
            keepSamePath: false,
            subtext: '',
            selectedStyle: 'style1',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Label',
                valueName: 'label',
            },
            {
                type: FieldType.TEXT,
                label: 'Link',
                valueName: 'helpPath',
            },
            {
                type: FieldType.SELECT,
                label: 'Justify (Horizontal alignment)',
                valueName: 'justify',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'flex-start', label: 'Start' },
                    { value: 'center', label: 'Center' },
                    { value: 'flex-end', label: 'End' },
                ],
            },
            {
                type: FieldType.TOGGLE,
                label: 'Keep same path',
                valueName: 'keepSamePath',
            },
            {
                type: FieldType.TEXT,
                label: 'Optional Markdown Subtext',
                valueName: 'subtext',
            },
            {
                type: FieldType.SELECT,
                label: 'Style (style1 or style2)',
                valueName: 'selectedStyle',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: 'style1', label: 'Style 1: Yellow' },
                    { value: 'style2', label: 'Style 2: White' },
                ],
            },
        ],
    },
    {
        name: 'Help Intro Section',
        identifier: 'helpIntroSection',
        initialData: {
            label: 'Title',
            text: 'Lorem ipsum',
            image: { __qbd_fileId: '', __qbd_altText: '' },
            buttons: [
                {
                    label: 'Button',
                    helpPath: '',
                    keepSamePath: true,
                },
            ],
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Label',
                valueName: 'label',
            },
            {
                type: FieldType.TEXT,
                label: 'Text',
                valueName: 'text',
                multiLine: true,
                lines: 5,
            },
            {
                type: FieldType.MEDIA,
                label: 'Image',
                valueName: 'image',
            },
            {
                type: FieldType.COLLECTION,
                title: 'Buttons',
                itemTitle: 'Button',
                valueName: 'buttons',
                fields: [
                    {
                        type: FieldType.TEXT,
                        label: 'Label',
                        valueName: 'label',
                    },
                    {
                        type: FieldType.TEXT,
                        label: 'Link',
                        valueName: 'helpPath',
                    },
                    {
                        type: FieldType.TOGGLE,
                        label: 'Keep same path',
                        valueName: 'keepSamePath',
                    },
                ],
            },
        ],
    },
    {
        name: 'Help Image',
        identifier: 'helpImage',
        initialData: {
            text: 'Lorem ipsum',
            image: { __qbd_fileId: '', __qbd_altText: '' },
            aspectRatio: '3/1',
            maxWidth: '',
        },
        fields: [
            {
                type: FieldType.TEXT,
                label: 'Text',
                valueName: 'text',
                multiLine: true,
                lines: 5,
            },
            {
                type: FieldType.MEDIA,
                label: 'Image',
                valueName: 'image',
            },
            {
                type: FieldType.SELECT,
                label: 'Aspect Ratio',
                valueName: 'aspectRatio',
                control: SelectControlType.DROPDOWN,
                options: [
                    { value: '3/2', label: '3:2' },
                    { value: '3/1', label: '3:1' },
                ],
            },
            {
                type: FieldType.TEXT,
                label: 'Max Width',
                valueName: 'maxWidth',
            },
        ],
    },
]
